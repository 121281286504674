<template>
	<div>
		<div class="content">
			<img src="../../../assets/new-bg.png" style="width: 100%;height: 100%;z-index: 1;position: absolute;top: 0;">
			<div class="login animate__animated animate__fadeIn">
				<div class="left">
					<img src="../../../assets/kyx_logo-transparant.png" style="width: 128px;margin: 24px 0px 0px 0px;" />
					<div style="margin: 32px 0px 0px 40px; font-size: 28px;">
						<div>欢迎加入</div>
						<div class="sys-name" style="font-weight: bold;"> 科研诚信管理系统 </div>
					</div>
					<div style="margin: 20px 84px 0px 40px; font-size: 12px; color: rgba(0, 0, 0, 0.65);">
						基于区块链技术的科研协作创新平台，为科研工作者提供便捷、专业的信息管理工具，让科研变的更从容。
					</div>
					<img src="../../../assets/register-bg.png"
						style="display: block;width: 352px;margin: 24px 74px 46px 74px;">
				</div>
				<div class="right">
					<div class="loginForm">
						<div
							style="font-size:24px;color: rgba(0, 0, 0, 0.85);font-weight: 550;font-family: PingFang SC Bold;margin: 24px;">
							账户登录</div>
						<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
							<a-tabs style="width: 85%;margin: 0 auto;" default-active-key="1" @change="changeLoginType">
								<a-tab-pane key="1" tab="密码登录" style="margin-top: 8px;">
									<div v-if="loginType == 1">
										<!-- 	<a-form-model-item has-feedback  prop="tenantId" >
										<a-select v-model="ruleForm.tenantId" style="width: 268px;" placeholder="请选择单位">
											<a-select-option v-for="(item, index) in renentList" :key="item.value" :value="item.value">
												{{ item.text }}
											</a-select-option>
										</a-select>
									</a-form-model-item> -->
										<a-form-model-item has-feedback prop="username">
											<a-input v-model="ruleForm.username" type="username" placeholder="请输入手机号码"
												autocomplete="off">
												<img src="../../../assets/user.png" slot="prefix">
											</a-input>
										</a-form-model-item>
										<a-form-model-item has-feedback prop="password">
											<a-input v-model="ruleForm.password" placeholder="请输入密码" type="password"
												autocomplete="off" @pressEnter="submitForm">
												<img src="../../../assets/lock-states.png" slot="prefix" style="">
											</a-input>
										</a-form-model-item>
									</div>
								</a-tab-pane>
								<a-tab-pane key="2" tab="验证码登录" force-render>
									<div v-if="loginType == 2">
										<!-- <a-form-model-item has-feedback  prop="tenantId" >
										<a-select v-model="ruleForm.tenantId" style="width: 268px;" placeholder="请选择单位">
											<a-select-option v-for="(item, index) in renentList" :key="item.value" :value="item.value">
												{{ item.text }}
											</a-select-option>
										</a-select>
									</a-form-model-item> -->
										<a-form-model-item has-feedback prop="phone">
											<a-input v-model="ruleForm.phone" type="phone" placeholder="请输入手机号码"
												autocomplete="off">
												<img src="../../../assets/user.png" slot="prefix">
											</a-input>
										</a-form-model-item>
										<a-form-model-item has-feedback prop="smsCode" class="smsInput">
											<a-input v-model="ruleForm.smsCode" placeholder="请输入验证码" autocomplete="off"
												@pressEnter="submitForm">
												<img src="../../../assets/shield-states.png" slot="prefix"
													style="width: 16px;">
											</a-input>
											<a-button :disabled="disabled" class="sms" @click="sendSms">{{ smsTxt }}
											</a-button>
										</a-form-model-item>
									</div>
								</a-tab-pane>
							</a-tabs>
						</a-form-model>
						<!-- @change="onChange" -->
						<div style="display: flex;justify-content: space-between;align-items: center;margin: 0 24px;">
							<a-checkbox style="font-size: 12px;" v-model="ruleForm.RememberPwd">
								7天内自动登录
							</a-checkbox>
							<div style="font-size: 12px;color: #1890FF;cursor: pointer;" @click="goToUpdatePass">忘记密码?
							</div>
						</div>
						<div style="display: flex;justify-content: center;margin-top: 50px;">
							<a-button
								:style="{ width: '268px', height: '40px', borderRadius: '4px' }"
								type="primary" @click="submitForm" :disabled="loading" :loading="loading">
								登录
							</a-button>
						</div>
						<div style="display: flex;justify-content: center;font-size: 12px;margin-top: 40px;">
							还没有账户？立即<span style="color: #FAAD14;cursor: pointer;" @click="goToRegister">注册</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import { setEncrypt } from '../../../tool/encryption.js'
import Footer from '../../../components/common/footer.vue'
import {
	login,
	getCode,
	phoneCodeLogin,
	getPublicRsaKey
} from '../../../api/anotherIndex';
import {
	getTenantList
} from '@/api/register.js'
import { message, Modal } from 'ant-design-vue'
export default {
	name: 'app',
	components: {
		Footer
	},
	data() {
		let validatorPhone = (rule, value, callback) => {
			// 如果为空值，就抛出错误
			if (!value) {
				callback(new Error("手机号不能为空!"))
			} else {
				// 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
				if (!/^1[2-9]\d{9}$/.test(value)) {
					callback(new Error("手机号格式不正确!"));
				} else {
					callback();
				}
			}
		}

		return {
			loginType: 1,
			smsTxt: '获取验证码',
			disabled: false,
			smsTime: '',
			ruleForm: {
				username: '',
				password: '',
				smsCode: '',
				phone: '',
				RememberPwd: false,
				tenantId: undefined
			},
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
			},
			rules: {
				username: [{
					required: true,
					message: '手机号码不能为空',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					message: '密码不能为空',
					trigger: 'blur'
				}],
				phone: [{
					required: true,
					validator: validatorPhone,
					trigger: 'blur'
				}
				],
				smsCode: [{
					required: true,
					message: '短信验证码不能为空',
					trigger: 'blur'
				}],
				/* tenantId: [{
					required: true,
					message: '请选择登录单位',
					trigger: 'blur'
				}] */
			},
			loading: false
		}
	},
	methods: {
		submitForm() {
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					this.loading = true;
					if (this.loginType == 1) {
						const resd = await getPublicRsaKey()
						if (resd.success) {
							const rsaKey = setEncrypt(resd.data, this.ruleForm.password)
							let data = {
								phoneOrEmail: this.ruleForm.username,
								password: rsaKey,
								RememberPwd: this.ruleForm.RememberPwd,
								tenantId: this.ruleForm.tenantId
							}
							const res = await login(data)
							if (res.success) {
								this.$message.success('登录成功', 2)
								window.localStorage.setItem('Authorization', res.data)
								localStorage.setItem('RememberPwd', this.ruleForm.RememberPwd);
								await this.$store.dispatch('callForUserInfo')
								await this.$store.dispatch('sysNotice/getNotice');
								this.$store.commit('setLoginTimeStamp');
								localStorage.setItem('pro__Access-Token', JSON.stringify({ value: localStorage.getItem('Authorization'), expire: null }));
								localStorage.setItem('pro__X-Access-Token', JSON.stringify({ value: localStorage.getItem('X-Authorization'), expire: null }));
								Modal.success({
									title: '登录成功！',
									content: '请做好保密工作，严禁录入涉密信息！',
									okText: '我已悉知',
									onOk: () => {
										if (this.$route.query.redirect) {
											this.$router.replace(this.$route.query.redirect)
										}
										else {
											this.$router.replace({
												path: '/workbench',
											}).then(() => {
												message.info({
													content: '请做好保密工作，严禁录入涉密信息！',
													duration: 3
												})
											})
										}
									}
								})

							}
						}
					} else if (this.loginType == 2) {
						let data = {
							phone: this.ruleForm.phone,
							code: this.ruleForm.smsCode,
							RememberPwd: this.ruleForm.RememberPwd,
							tenantId: this.ruleForm.tenantId
						}
						const res = await phoneCodeLogin(data)
						if (res.success) {
							this.$message.success('登录成功', 2)
							window.localStorage.setItem('Authorization', res.data)
							localStorage.setItem('RememberPwd', this.ruleForm.RememberPwd);
							await this.$store.dispatch('callForUserInfo')
							await this.$store.dispatch('sysNotice/getNotice');
							this.$store.commit('setLoginTimeStamp');
							localStorage.setItem('pro__Access-Token', JSON.stringify({ value: localStorage.getItem('Authorization'), expire: null }));
							localStorage.setItem('pro__X-Access-Token', JSON.stringify({ value: localStorage.getItem('X-Authorization'), expire: null }));
							Modal.success({
								title: '登录成功！',
								content: '请做好保密工作，严禁录入涉密信息！',
								okText: '我已悉知',
								onOk: () => {
									if (this.$route.query.redirect) {
										this.$router.replace(this.$route.query.redirect)
									}
									else {
										this.$router.replace({
											path: '/workbench',
										}).then(() => {
											message.info({
												content: '请做好保密工作，严禁录入涉密信息！',
												duration: 3
											})
										})
									}
								}
							})
						} 
					}
					this.loading = false;
				} else {
					console.log('error submit!!');
					return
				}
			});
		},
		changeLoginType(val) {
			this.loginType = val
		},
		sendSms() {
			this.$refs.ruleForm.validateField(["phone"], async (valid) => {
				if (valid == '') {
					this.smsTime = 60
					let data = {
						"phone": this.ruleForm.phone,
						"codeType": 2,
						//tenantId: this.ruleForm.tenantId
					}
					const res = await getCode(data)
					if (res.success) {
						this.$message.success('验证码已发送，请注意查收')
						this.timer()
					}
				} else {
					return
				}
			})
		},
		timer() {
			if (this.smsTime > 0) {
				this.disabled = true
				this.smsTime--
				this.smsTxt = this.smsTime + "秒再发"
				setTimeout(this.timer, 1000)
			} else {
				this.smsTime = 0
				this.smsTxt = "获取验证码"
				this.disabled = false
			}
		},
		goToRegister() {
			this.$router.push({
				path: '/register'
			})
		},
		goToUpdatePass() {
			this.$router.push({
				path: '/updatePass'
			})
		}
	},
	async mounted() {
		/* const res = await getTenantList();
		if(res.code === 200) {
			this.renentList = res.data;
		} */
	}
}
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	min-height: 820px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 110px;
	background: #3A5BDC;
}

.login {
	//width: 792px;
	min-height: 480px;
	box-shadow: 0px 26px 48px 0px rgba(0, 38, 191, 0.25);
	z-index: 20;
	display: flex;
	border-radius: 16px;
	overflow: hidden;
}

.left {
	width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 24px;
	background: #F1F5FB;
}

.right {
	padding: 0px 94px;
	//width: 319px;
	min-height: 480px;
	//border-radius: 4px;
	background: #FFFFFF;
	//margin-top: 8px;
	display: flex;
	align-items: center;

	.loginForm {
		width: 319px;
	}
}

.sms {
	position: absolute;
	top: -10px;
	right: -110px;
	width: 88px;
	height: 40px;
	border-radius: 4px;
	background: #F0F5FF;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: @srims-primary-color;
	border-color: transparent !important;
}

/deep/ .loginForm {
	.ant-select-selection {
		background: #F7F8FA;
		height: 40px;
		font-size: 14px;
	}

	/* .ant-select-selection__placeholder {
			line-height: 26px;
			font-size: 14px;
		} */
	.ant-select-selection__rendered {
		line-height: 40px;
	}
}

.sys-name {
	color: @srims-primary-color;
}
</style>
<style lang="less" scoped>

.content {
	/deep/ .loginForm {
		.ant-tabs-nav-wrap {
			background: #FFFFFF !important;
		}

		.ant-tabs-nav {
			transform: translateX(-24px);
		}

		.ant-tabs-tab-active {
			color: rgba(0, 0, 0, 0.85) !important;
		}

		.ant-tabs-tab {
			color: rgba(0, 0, 0, 0.45) !important;
			font-weight: 500;
			font-size: 14px !important;
			text-align: left;
			padding: 12px;
		}
		.ant-tabs-ink-bar {
			/* width: 65px !important;
			left: 12px !important;
			width: 50px; */
			/* top: 88px !important; */
		}
		.ant-input {
			width: 268px !important;
			height: 40px !important;
			border-radius: 4px;
			background: #F7F8FA;
		}

		.ant-tabs-tab3ane {
			width: 268px !important;
		}

		.ant-tabs-tab {
			margin-left: 18px !important;

		}
		.ant-form-item-children-icon {
			left: 230px;
		}
	}
	/deep/ .smsInput .ant-input {
	width: 172px !important;
}

	/deep/ .smsInput .ant-form-item-children-icon {
		left: 135px !important;
	}
	.sys-name {
		color: @srims-primary-color;
	}
}

</style>